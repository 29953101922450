import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import "../assets/components/bootstrap/css/bootstrap.min.css"

import "../assets/scss/theme.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Logo from "../../static/images/Clean_logo.svg"
import Icon1 from "../../static/images/icon1.svg"
import Icon2 from "../../static/images/icon2.svg"
import Icon3 from "../../static/images/icon3.svg"

const App = () => {
  const query = useStaticQuery(graphql`
    query {
      shopping: file(relativePath: { eq: "shopping_sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 730) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      shopping_md: file(relativePath: { eq: "shopping_md.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      shopping_lg: file(relativePath: { eq: "shopping_lg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  /**/

  return (
    <Layout>
      <SEO title="Home" />
      <header id="top">
        <div className="logo text-center">
          <Logo className="my-4 my-md-6" />
        </div>
        <section id="intro" className="">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h1 className="">Welcome to the world of clean payments.</h1>
                </header>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center px-6">
                Imagine a world where everything you bought online was CO2
                neutral. Where all of your purchases, regardless of product or
                service, were manufactured and delivered without any carbon
                footprint. So that you’d be saving the world just a tiny little
                bit every time you shop online…?
                <br />
                <br />
                <span className="h4">Enter CleanPay.</span>
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div
            className="w-100 gatsby-image-wrapper"
            style={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              aria-hidden="true"
              style={{ width: "100%", paddingBottom: "54.5%" }}
            ></div>
            <picture>
              <img
                src="/images/heroimage.png"
                alt=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            </picture>
          </div>
        </div>
      </header>

      <main className="main-container">
        <section id="intro_continued" className="section pt-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                CleanPay is built by experts with more than 20 years experience
                of online payment solutions. The CleanPay solution adapts
                seamlessly to your web shop, and through our eco-friendly,
                CO2-neutral angle, your customers will enjoy making payments
                feeling they contribute to a better world every time they pay.
                <br />
                <br />
                We bring together everything that is required to integrate
                payment solutions to your web shop, providing payment services
                for online retailers, subscriptions businesses, software
                platforms and marketplaces and everything in between, as well as
                algorithms to help you beat fraud.
              </div>
            </div>
          </div>
        </section>
        <section id="unique_angle" className="section pt-0">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h2 className="h1">Our unique environment angle</h2>
                </header>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                CleanPay is a new e-commerce payment service provider that lets
                customers compensate the CO2 impact created by the manufacturing
                and distribution of goods and services bought online - as well
                as providing a reliable, secure and state-of-the-art payment
                service to e-merchants.
                <br />
                <br />
                CleanPay calculates the CO2 impact of every purchase based on a
                third-party index that connects the purchase price with kilos of
                carbon spent. At the customers request, CleanPay compensates the
                equivalent amount of CO2 of the purchase from Climate Neutral
                Now, a UN certified and accredited initiative for carbon offset.
                <br />
                <br />
                And, not only are the purchases made CO2 neutral - the actual
                payment processing is environmentally friendly too. All of
                CleanPay’s servers and computer processing power rely on a
                sustainable data center in Finland, ensuring that the energy
                consumed is 100% eco-friendly (a 50/50 combination of hydro and
                wind power).
              </div>
            </div>
          </div>
        </section>
        <section id="how_to_shop" className="section pt-0">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h2 className="h1">
                    So how to shop online without creating <br /> a carbon
                    footprint?
                  </h2>
                </header>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-4 text-center px-4 pb-2 py-md-2">
                <Icon1 className="px-sm-3 px-md-0" style={{ width: "35%" }} />
                <div className="mt-2">
                  When customers checkout and pay, they choose the CleanPay
                  carbon offset option.
                </div>
              </div>
              <div className="col-12 col-md-4 text-center px-4 py-2">
                <Icon2 className="px-sm-3 px-md-0" style={{ width: "35%" }} />
                <div className="mt-2">
                  When checking the box to compensate for the CO2 footprint of
                  the purchase, CleanPay automatically calculates the
                  compensation cost and adds the sum to the total bill.
                </div>
              </div>
              <div className="col-12 col-md-4 text-center px-4 py-2">
                <Icon3 className="px-sm-3 px-md-0" style={{ width: "35%" }} />
                <div className="mt-2">
                  Pay and it’s done! The purchase is processed AND has been CO2
                  compensated. And the customer has saved the world a tiny
                  little bit.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services">
          <div className="position-relative">
            <div className="position-relative">
              <Img
                className="w-100"
                fluid={[
                  query.shopping.childImageSharp.fluid,
                  {
                    ...query.shopping_lg.childImageSharp.fluid,
                    media: `(min-width: 1400px)`,
                  },
                  {
                    ...query.shopping_md.childImageSharp.fluid,
                    media: `(min-width: 730px)`,
                  },
                ]}
                style={{ minHeight: 500, maxHeight: 700 }}
              />
            </div>
            <div className="position-absolute p-top p-right p-left p-bottom d-flex justify-content-center flex-column">
              <div className="container">
                <div className="text-center px-md-8">
                  <h2 className="text-white fm-regular text-shadow">
                    CleanPay is gradually rolling out its services to the
                    e-commerce world, offering e-shoppers and e-merchants alike
                    a way to reduce environmental impact from online shopping
                    and help the planet we all share.
                  </h2>
                  <br />
                  <h2 className="text-white text-shadow">
                    CleanPay - welcome to the world of clean payments.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default App
